.avatar {
    height: 64px;
    margin-left: 19px;
    margin-right: 19px;
    width: 64px;
}

.avatar.float {
    float: left;
}

.avatar > .image {
    border-radius: 4px 4px 4px 4px;
    -o-border-radius: 4px 4px 4px 4px;
    -ms-border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -khtml-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
    height: 100%;
    width: 100%;
}
