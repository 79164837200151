.quick-action {
    margin-bottom: 24px;
}

.quick-action > .header {
    border-bottom: 1px solid #eaeaea;
    color: #2d2d2d;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    margin-top: 12px;
    padding-bottom: 7px;
}
