.side-menu {
    list-style: none;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.side-menu > li a {
    border-radius: 6px 6px 6px 6px;
    -o-border-radius: 6px 6px 6px 6px;
    -ms-border-radius: 6px 6px 6px 6px;
    -moz-border-radius: 6px 6px 6px 6px;
    -khtml-border-radius: 6px 6px 6px 6px;
    -webkit-border-radius: 6px 6px 6px 6px;
    color: #6d6d6d;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 1px;
    padding: 0px 14px 0px 14px;
}

.side-menu > li a:hover {
    background-color: rgba(208, 215, 222, 0.24);
}

.side-menu > li.selected a {
    background-color: rgba(208, 215, 222, 0.24);
    color: #2d2d2d;
    font-weight: 600;
}
