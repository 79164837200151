.input-pair {
    display: flex;
    padding: 8px 0px 8px 0px;
    width: 100%;
}

.input-pair > .left {
    padding-right: 12px;
}

.input-pair > .right {
    flex: 1 1;
}
