.footer {
    color: #2d2d2d;
    font-size: 12px;
    margin: 0px auto 0px auto;
    max-width: 100%;
    padding: 22px 0px 22px 0px;
    text-align: center;
    width: 960px;
}

.footer.dark {
    color: #ffffff;
}

.footer > .footer-message {
    border-bottom: 1px dashed #eaeaea;
    margin: 0px auto 0px auto;
    padding: 0px 0px 8px 0px;
    width: 200px;
}

.footer > .footer-message > .value,
.footer > .footer-message > .link {
    margin-left: 6px;
}

.footer > .footer-language {
    margin: 8px auto 0px auto;
}

.footer > .footer-language > .link {
    margin-right: 6px;
}

.footer > .footer-language > .link:last-child {
    margin-right: 0px;
}

.footer > .footer-warning {
    margin: 8px auto 0px auto;
}

.footer > .footer-timing {
    margin: 8px auto 0px auto;
}
