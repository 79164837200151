.header-logo {
    background: transparent url(./res/header-logo.png) no-repeat center center;
    cursor: pointer;
    display: inline-block;
    height: 80px;
    text-align: center;
    width: 190px;
}

.header-logo.float {
    display: block;
    float: left;
}

body.retina-s .header-logo {
    background-image: url(./res/header-logo-2x.png);
    background-size: 190px 80px;
}

.header-logo.warning {
    background-image: url(./res/header-logo-warning.png);
}

body.retina-s .header-logo.warning {
    background-image: url(./res/header-logo-warning-2x.png);
}

.header-logo > .image {
    border: none;
    width: 100%;
}
