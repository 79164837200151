.radio-pair {
    display: inline-block;
    line-height: 32px;
}

.radio-pair > .children {
    color: #2d2d2d;
    font-size: 12.5px;
    line-height: 17px;
    margin-left: 6px;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
}
