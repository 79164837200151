.viewport {
    font-size: 13px;
    margin: 0px auto 0px auto;
    width: 100%;
    min-height: 100%;
}

.viewport.dark {
    background-image: url(./res/bg_grey.jpg);
}

.viewport.centered {
    text-align: center;
}
