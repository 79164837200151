.content-header > .header-contents {
    direction: row;
    display: flex;
}

.content-header > .header-contents > .right {
    flex: 1 1;
}

.content-header.compressed > .header-contents > .right > .title {
    line-height: 7px;
}
