.radio-field {
    appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    -khtml-appearance: none;
    -webkit-appearance: none;
    background: #fff url(./res/radio-field.png) no-repeat 0px 0px;
    border: 1px solid #bbb;
    border-radius: 3px 3px 3px 3px;
    -o-border-radius: 3px 3px 3px 3px;
    -ms-border-radius: 3px 3px 3px 3px;
    -moz-border-radius: 3px 3px 3px 3px;
    -khtml-border-radius: 3px 3px 3px 3px;
    -webkit-border-radius: 3px 3px 3px 3px;
    color: #818285;
    cursor: pointer;
    height: 17px;
    margin: 0px 0px 0px 0px;
    vertical-align: middle;
    width: 17px
}

body.retina-s .radio-field {
    background-image: url(./res/radio-field-2x.png);
    background-size: 15px 90px
}

.radio-field:hover {
    background-position: 0px -15px
}

.radio-field:checked {
    background-position: 0px -45px
}

.radio-field:checked:hover {
    background-position: 0px -60px
}

.radio-field:focus {
    outline: none;
    -o-outline: none;
    -ms-outline: none;
    -moz-outline: none;
    -khtml-outline: none;
    -webkit-outline: none
}
