.paragraph {
    line-height: 24px;
    margin: 6px 0px 6px 0px;
}

.paragraph.condensed {
    font-size: 12px;
    line-height: 20px;
}

.paragraph.dark {
    color: #000000;
}
