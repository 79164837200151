.button,
.button-container > .button {
    background: #598543 url(./res/button-green.png) repeat-x left top;
    border: 1px solid #598543;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    line-height: 36px;
    padding: 0px 18px 0px 18px;
    text-decoration: none;
    text-shadow: 0px -1px 0px #4a7b38;
    -o-text-shadow: 0px -1px 0px #4a7b38;
    -ms-text-shadow: 0px -1px 0px #4a7b38;
    -moz-text-shadow: 0px -1px 0px #4a7b38;
    -khtml-text-shadow: 0px -1px 0px #4a7b38;
    -webkit-text-shadow: 0px -1px 0px #4a7b38;
    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

body.retina-s .button,
body.retina-s .button-container > .button {
    background-image: url(./res/button-green-2x.png);
    background-size: 2px 108px;
}

.button:hover,
.button:focus,
body.retina-s .button-container > .button:hover,
body.retina-s .button-container > .button:focus {
    background-position: left -36px;
}

.button:active,
body.retina-s .button-container > .button:active {
    background-position: left -72px;
}

.button.disabled,
body.retina-s .button-container > .button.disabled {
    background-position: left top;
    opacity: 0.6;
    -o-opacity: 0.6;
    -ms-opacity: 0.6;
    -moz-opacity: 0.6;
    -khtml-opacity: 0.6;
    -webkit-opacity: 0.6
}
