.top-bar {
    background-color: #f0f0f0;
    border-bottom: 1px solid #dddddd;
    box-shadow: 0px 1px 4px #eeeeee;
    -o-box-shadow: 0px 1px 4px #eeeeee;
    -ms-box-shadow: 0px 1px 4px #eeeeee;
    -moz-box-shadow: 0px 1px 4px #eeeeee;
    -khtml-box-shadow: 0px 1px 4px #eeeeee;
    -webkit-box-shadow: 0px 1px 4px #eeeeee;
    height: 61px;
    left: 0px;
    line-height: 60px;
    position: absolute;
    top: 0px;
    width: 100%;
}

.top-bar-container {
    margin: 0px auto 0px auto;
    padding: 0px 0px 0px 0px;
}
