.tag {
    background-color: #999999;
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
    padding: 3px 6px 3px 6px;
    text-transform: uppercase;
}

.tag.grey {
    background-color: #999999;
}

.tag.green {
    background-color: #46a546;
}

.tag.orange {
    background-color: #f89406;
}

.tag.red {
    background-color: #c43c35;
}

.tag.blue {
    background-color: #62cffc;
}
