.avatar {
  width: 64px;
  height: 64px;
  margin-left: 19px;
  margin-right: 19px;
}

.avatar.float {
  float: left;
}

.avatar > .image {
  -o-border-radius: 4px 4px 4px 4px;
  -ms-border-radius: 4px 4px 4px 4px;
  -khtml-border-radius: 4px 4px 4px 4px;
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.block {
  display: block;
}

.block.centered {
  text-align: center;
}

.button, .button-container > .button {
  color: #fff;
  cursor: pointer;
  text-shadow: 0 -1px #4a7b38;
  -o-text-shadow: 0px -1px 0px #4a7b38;
  -ms-text-shadow: 0px -1px 0px #4a7b38;
  -moz-text-shadow: 0px -1px 0px #4a7b38;
  -khtml-text-shadow: 0px -1px 0px #4a7b38;
  -webkit-text-shadow: 0px -1px 0px #4a7b38;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  background: #598543 url("button-green.b5661351.png") repeat-x;
  border: 1px solid #598543;
  padding: 0 18px;
  font-size: 13px;
  font-weight: 600;
  line-height: 36px;
  text-decoration: none;
  display: inline-block;
}

body.retina-s .button, body.retina-s .button-container > .button {
  background-image: url("button-green-2x.7a9a5c80.png");
  background-size: 2px 108px;
}

.button:hover, .button:focus, body.retina-s .button-container > .button:hover, body.retina-s .button-container > .button:focus {
  background-position: 0 -36px;
}

.button:active, body.retina-s .button-container > .button:active {
  background-position: 0 -72px;
}

.button.disabled, body.retina-s .button-container > .button.disabled {
  opacity: .6;
  -o-opacity: .6;
  -ms-opacity: .6;
  -moz-opacity: .6;
  -khtml-opacity: .6;
  -webkit-opacity: .6;
  background-position: 0 0;
}

.check-field {
  appearance: none;
  -o-appearance: none;
  -khtml-appearance: none;
  -o-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -khtml-border-radius: 3px 3px 3px 3px;
  color: #818285;
  cursor: pointer;
  vertical-align: middle;
  background: #fff url("check-field.9b10bb5b.png") no-repeat;
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 17px;
  height: 17px;
  margin: 0;
}

body.retina-s .check-field {
  background-image: url("check-field-2x.c539fbc9.png");
  background-size: 15px 90px;
}

.check-field:hover {
  background-position: 0 -15px;
}

.check-field:checked {
  background-position: 0 -45px;
}

.check-field:checked:hover {
  background-position: 0 -60px;
}

.check-field:focus {
  -o-outline: none;
  -ms-outline: none;
  -moz-outline: none;
  -khtml-outline: none;
  -webkit-outline: none;
  outline: none;
}

.link, .link-container > a {
  color: #3668c6;
  cursor: pointer;
  -o-outline: none;
  -ms-outline: none;
  -moz-outline: none;
  -khtml-outline: none;
  -webkit-outline: none;
  border-bottom: 1px solid #0000;
  outline: none;
  padding-bottom: 1px;
  text-decoration: none;
}

.dark .link, .dark .link-container > a {
  color: #fff;
}

.link:hover, .link:focus, .link.selected, .link-container > a:hover, .link-container > a:focus, .link-container.selected > a {
  border-bottom: 1px solid #3668c6;
}

.dark .link:hover, .dark .link:focus, .dark .link.selected, .dark .link-container > a:hover, .dark .link-container > a:focus, .dark .link-container.selected > a {
  border-bottom: 1px solid #fff;
}

.link.blue, .link-container.blue > a {
  color: #3668c6;
}

.link.blue:hover, .link.blue:focus, .link.blue.selected, .link-container.blue > a:hover, .link-container.blue > a:focus, .link-container.blue.selected > a:hover {
  border-bottom: 1px solid #3668c6;
}

.link.green, .link-container.green > a {
  color: #32951b;
}

.link.green:hover, .link.green:focus, .link.green.selected, .link-container.green > a:hover, .link-container.green > a:focus, .link-container.green.selected > a {
  border-bottom: 1px solid #32951b;
}

.link.red, .link-container.red > a {
  color: #c31f30;
}

.link.red:hover, .link.red:focus, .link.red.selected, .link-container.red > a:hover, .link-container.red > a:focus, .link-container.red.selected > a {
  border-bottom: 1px solid #c31f30;
}

.link.grey, .link-container.grey > a {
  color: #888;
}

.link.grey:hover, .link.grey:focus, .link.grey.selected, .link-container.grey > a:hover, .link-container.grey > a:focus, .link-container.grey.selected > a {
  border-bottom: 1px solid #888;
}

.link.grey-light, .link-container.grey-light > a {
  color: #4d4d4d;
}

.link.grey-light:hover, .link.grey-light:focus, .link.grey-light.selected, .link-container.grey-light > a:hover, .link-container.grey-light > a:focus, .link-container.grey-light.selected > a {
  border-bottom: 1px solid #4d4d4d;
}

.link.grey-dark, .link-container.grey-dark > a {
  color: #2d2d2d;
}

.link.grey-dark:hover, .link.grey-dark:focus, .link.grey-dark.selected, .link-container.grey-dark > a:hover, .link-container.grey-dark > a:focus, .link-container.grey-dark.selected > a {
  border-bottom: 1px solid #2d2d2d;
}

.link.grey-darker, .link-container.grey-darker > a {
  color: #000;
}

.link.grey-darker:hover, .link.grey-darker:focus, .link.grey-darker.selected, .link-container.grey-darker > a:hover, .link-container.grey-darker > a:focus, .link-container.grey-darker.selected > a {
  border-bottom: 1px solid #000;
}

.link.strong, .link-container.strong > a {
  font-weight: 500;
}

.link.block, .link-container.block > a {
  width: 100%;
  display: inline-block;
}

.link.no-style, .link-container.no-style > a {
  border: none;
}

.link.no-select, .link-container.no-select > a {
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
}

.header-logo {
  cursor: pointer;
  text-align: center;
  background: url("header-logo.036035ca.png") center no-repeat;
  width: 190px;
  height: 80px;
  display: inline-block;
}

.header-logo.float {
  float: left;
  display: block;
}

body.retina-s .header-logo {
  background-image: url("header-logo-2x.a01c8d42.png");
  background-size: 190px 80px;
}

.header-logo.warning {
  background-image: url("header-logo-warning.b581c6cc.png");
}

body.retina-s .header-logo.warning {
  background-image: url("header-logo-warning-2x.1a0a20e6.png");
}

.header-logo > .image {
  border: none;
  width: 100%;
}

.input-pair {
  width: 100%;
  padding: 8px 0;
  display: flex;
}

.input-pair > .left {
  padding-right: 12px;
}

.input-pair > .right {
  flex: 1;
}

.label {
  cursor: text;
  width: 100%;
  max-width: 100%;
  min-height: 32px;
  margin: 0;
  padding: 4px 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  display: inline-block;
}

.label.light {
  font-weight: 400;
}

.label.underline {
  text-underline-offset: 6px;
  text-decoration: underline;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.padding {
  display: inline-block;
}

.paragraph {
  margin: 6px 0;
  line-height: 24px;
}

.paragraph.condensed {
  font-size: 12px;
  line-height: 20px;
}

.paragraph.dark {
  color: #000;
}

.radio-field {
  appearance: none;
  -o-appearance: none;
  -khtml-appearance: none;
  -o-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -khtml-border-radius: 3px 3px 3px 3px;
  color: #818285;
  cursor: pointer;
  vertical-align: middle;
  background: #fff url("radio-field.728b6982.png") no-repeat;
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 17px;
  height: 17px;
  margin: 0;
}

body.retina-s .radio-field {
  background-image: url("radio-field-2x.915911f0.png");
  background-size: 15px 90px;
}

.radio-field:hover {
  background-position: 0 -15px;
}

.radio-field:checked {
  background-position: 0 -45px;
}

.radio-field:checked:hover {
  background-position: 0 -60px;
}

.radio-field:focus {
  -o-outline: none;
  -ms-outline: none;
  -moz-outline: none;
  -khtml-outline: none;
  -webkit-outline: none;
  outline: none;
}

.separator {
  border-bottom: 1px solid #eaeaea;
  margin: 16px 0;
}

.tag {
  color: #fff;
  text-transform: uppercase;
  background-color: #999;
  padding: 3px 6px;
  font-size: 10px;
  font-weight: 600;
}

.tag.grey {
  background-color: #999;
}

.tag.green {
  background-color: #46a546;
}

.tag.orange {
  background-color: #f89406;
}

.tag.red {
  background-color: #c43c35;
}

.tag.blue {
  background-color: #62cffc;
}

.text-area {
  -o-box-shadow: 0px 1px 0px #fff;
  -ms-box-shadow: 0px 1px 0px #fff;
  -khtml-box-shadow: 0px 1px 0px #fff;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 100%;
  max-width: 100%;
  height: 100px;
  padding: 6px;
  font-size: 13px;
  line-height: 20px;
  box-shadow: 0 1px #fff;
}

.text-area.large {
  height: 200px;
}

body.transitions .text-area {
  -o-transition: border-color .1s ease-in;
  -khtml-transition: border-color .1s ease-in;
  transition: border-color .1s ease-in;
}

.text-area::placeholder {
  opacity: .7;
  -o-opacity: .7;
  -ms-opacity: .7;
  -moz-opacity: .7;
  -khtml-opacity: .7;
  -webkit-opacity: .7;
}

.text-area:focus {
  -o-outline: none;
  -ms-outline: none;
  -moz-outline: none;
  -khtml-outline: none;
  -webkit-outline: none;
  border: 1px solid #3668c6;
  outline: none;
}

.text-input {
  -o-box-shadow: 0px 1px 0px #fff;
  -ms-box-shadow: 0px 1px 0px #fff;
  -khtml-box-shadow: 0px 1px 0px #fff;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 100%;
  max-width: 100%;
  height: 32px;
  padding: 2px 6px;
  font-size: 13px;
  box-shadow: 0 1px #fff;
}

body.transitions .text-input {
  -o-transition: border-color .1s ease-in;
  -khtml-transition: border-color .1s ease-in;
  transition: border-color .1s ease-in;
}

.text-input:focus {
  -o-outline: none;
  -ms-outline: none;
  -moz-outline: none;
  -khtml-outline: none;
  -webkit-outline: none;
  border: 1px solid #3668c6;
  outline: none;
}

.text-input::placeholder {
  opacity: .7;
  -o-opacity: .7;
  -ms-opacity: .7;
  -moz-opacity: .7;
  -khtml-opacity: .7;
  -webkit-opacity: .7;
}

.text-input.large {
  height: 38px;
}

.text {
  margin: 6px 0;
  line-height: 24px;
}

.text.condensed {
  font-size: 12px;
  line-height: 20px;
}

.text.dark {
  color: #000;
}

.title {
  max-width: 100%;
  margin: 5px 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
}

.viewport {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  font-size: 13px;
}

.viewport.dark {
  background-image: url("bg_grey.0044fd22.jpg");
}

.viewport.centered {
  text-align: center;
}

.check-pair {
  line-height: 32px;
  display: inline-block;
}

.check-pair > .children {
  color: #2d2d2d;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  margin-left: 6px;
  font-size: 12.5px;
  line-height: 17px;
  display: inline-block;
}

.content-header > .header-contents {
  direction: row;
  display: flex;
}

.content-header > .header-contents > .right {
  flex: 1;
}

.content-header.compressed > .header-contents > .right > .title {
  line-height: 7px;
}

.content-panel {
  -o-transition-duration: .5s;
  -khtml-transition-duration: .5s;
  text-align: left;
  border: 1px solid #eee;
  margin: 0 auto;
  padding: 16px;
  transition-duration: .5s;
}

.content-panel.round {
  -o-border-radius: 6px 6px 6px 6px;
  -ms-border-radius: 6px 6px 6px 6px;
  -khtml-border-radius: 6px 6px 6px 6px;
  border: none;
  border-radius: 6px;
}

.content-panel.dark {
  -o-box-shadow: 0px 0px 10px #3d3d3d;
  -ms-box-shadow: 0px 0px 10px #3d3d3d;
  -khtml-box-shadow: 0px 0px 10px #3d3d3d;
  background-color: #fff;
  box-shadow: 0 0 10px #3d3d3d;
}

.content-panel.centered {
  text-align: center;
}

.content {
  border-bottom: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  width: 100%;
  margin-bottom: 22px;
  padding: 16px;
}

.context-bar {
  width: 214px;
  height: 100%;
  padding: 16px 0 0 28px;
}

.error-box {
  border: 1px solid #de737c;
  margin: 0 0 16px;
  padding: 12px;
}

.error-box > .error-title {
  color: #c9273f;
  margin: 0;
  font-size: 16px;
}

.error-box > .error-description {
  color: #4d4d4d;
  margin: 4px 0 0;
}

.footer {
  color: #2d2d2d;
  text-align: center;
  width: 960px;
  max-width: 100%;
  margin: 0 auto;
  padding: 22px 0;
  font-size: 12px;
}

.footer.dark {
  color: #fff;
}

.footer > .footer-message {
  border-bottom: 1px dashed #eaeaea;
  width: 200px;
  margin: 0 auto;
  padding: 0 0 8px;
}

.footer > .footer-message > .value, .footer > .footer-message > .link {
  margin-left: 6px;
}

.footer > .footer-language {
  margin: 8px auto 0;
}

.footer > .footer-language > .link {
  margin-right: 6px;
}

.footer > .footer-language > .link:last-child {
  margin-right: 0;
}

.footer > .footer-warning, .footer > .footer-timing {
  margin: 8px auto 0;
}

.form > .submit {
  display: none;
}

.main-container {
  flex-direction: row;
  padding-top: 61px;
  display: flex;
}

.main-container > .main-center {
  flex: 1;
}

.quick-action {
  margin-bottom: 24px;
}

.quick-action > .header {
  color: #2d2d2d;
  border-bottom: 1px solid #eaeaea;
  margin-top: 12px;
  padding-bottom: 7px;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
}

.radio-group {
  line-height: 32px;
  display: inline-block;
}

.radio-group > .radio-pair {
  margin-right: 16px;
}

.radio-pair {
  line-height: 32px;
  display: inline-block;
}

.radio-pair > .children {
  color: #2d2d2d;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  margin-left: 6px;
  font-size: 12.5px;
  line-height: 17px;
  display: inline-block;
}

.side-bar {
  width: 180px;
  height: 100%;
  padding: 24px 12px;
}

.side-menu {
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.side-menu > li a {
  -o-border-radius: 6px 6px 6px 6px;
  -ms-border-radius: 6px 6px 6px 6px;
  -khtml-border-radius: 6px 6px 6px 6px;
  color: #6d6d6d;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 1px;
  padding: 0 14px;
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
}

.side-menu > li a:hover {
  background-color: #d0d7de3d;
}

.side-menu > li.selected a {
  color: #2d2d2d;
  background-color: #d0d7de3d;
  font-weight: 600;
}

.top-bar {
  -o-box-shadow: 0px 1px 4px #eee;
  -ms-box-shadow: 0px 1px 4px #eee;
  -khtml-box-shadow: 0px 1px 4px #eee;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
  width: 100%;
  height: 61px;
  line-height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 4px #eee;
}

.top-bar-container {
  margin: 0 auto;
  padding: 0;
}
/*# sourceMappingURL=index.23f8c3b2.css.map */
