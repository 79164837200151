.content-panel {
    border: 1px solid #eeeeee;
    padding: 16px 16px 16px 16px;
    transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -khtml-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    margin: 0px auto 0px auto;
    text-align: left;
}

.content-panel.round {
    border: none;
    border-radius: 6px 6px 6px 6px;
    -o-border-radius: 6px 6px 6px 6px;
    -ms-border-radius: 6px 6px 6px 6px;
    -moz-border-radius: 6px 6px 6px 6px;
    -khtml-border-radius: 6px 6px 6px 6px;
    -webkit-border-radius: 6px 6px 6px 6px;
}

.content-panel.dark {
    background-color: #ffffff;
    box-shadow: 0px 0px 10px #3d3d3d;
    -o-box-shadow: 0px 0px 10px #3d3d3d;
    -ms-box-shadow: 0px 0px 10px #3d3d3d;
    -moz-box-shadow: 0px 0px 10px #3d3d3d;
    -khtml-box-shadow: 0px 0px 10px #3d3d3d;
    -webkit-box-shadow: 0px 0px 10px #3d3d3d;
}

.content-panel.centered {
    text-align: center;
}
