.text-input {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    box-shadow: 0px 1px 0px #ffffff;
    -o-box-shadow: 0px 1px 0px #ffffff;
    -ms-box-shadow: 0px 1px 0px #ffffff;
    -moz-box-shadow: 0px 1px 0px #ffffff;
    -khtml-box-shadow: 0px 1px 0px #ffffff;
    -webkit-box-shadow: 0px 1px 0px #ffffff;
    font-size: 13px;
    height: 32px;
    max-width: 100%;
    padding: 2px 6px 2px 6px;
    width: 100%;
}

body.transitions .text-input {
    transition: border-color 0.10s ease-in;
    -o-transition: border-color 0.10s ease-in;
    -ms-transition: border-color 0.10s ease-in;
    -moz-transition: border-color 0.10s ease-in;
    -khtml-transition: border-color 0.10s ease-in;
    -webkit-transition: border-color 0.10s ease-in;
}

.text-input:focus {
    border: 1px solid #3668c6;
    outline: none;
    -o-outline: none;
    -ms-outline: none;
    -moz-outline: none;
    -khtml-outline: none;
    -webkit-outline: none;
}

.text-input::placeholder {
    opacity: 0.7;
    -o-opacity: 0.7;
    -ms-opacity: 0.7;
    -moz-opacity: 0.7;
    -khtml-opacity: 0.7;
    -webkit-opacity: 0.7;
}

.text-input.large {
    height: 38px;
}
