.label {
    cursor: text;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    margin: 0px 0px 0px 0px;
    max-width: 100%;
    min-height: 32px;
    padding: 4px 0px 4px 0px;
    width: 100%;
}

.label.light {
    font-weight: 400;
}

.label.underline {
    text-decoration: underline;
    text-underline-offset: 6px;
}
