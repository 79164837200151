.error-box {
    border: 1px solid #de737c;
    margin: 0px 0px 16px 0px;
    padding: 12px 12px 12px 12px;
}

.error-box > .error-title {
    color: #c9273f;
    font-size: 16px;
    margin: 0px 0px 0px 0px
}

.error-box > .error-description {
    color: #4d4d4d;
    margin: 4px 0px 0px 0px
}
