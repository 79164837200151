.link,
.link-container > a {
    border-bottom: 1px solid transparent;
    color: #3668c6;
    cursor: pointer;
    outline: none;
    -o-outline: none;
    -ms-outline: none;
    -moz-outline: none;
    -khtml-outline: none;
    -webkit-outline: none;
    padding-bottom: 1px;
    text-decoration: none;
}

.dark .link,
.dark .link-container > a {
    color: #ffffff;
}

.link:hover,
.link:focus,
.link.selected,
.link-container > a:hover,
.link-container > a:focus,
.link-container.selected > a {
    border-bottom: 1px solid #3668c6;
}

.dark .link:hover,
.dark .link:focus,
.dark .link.selected,
.dark .link-container > a:hover,
.dark .link-container > a:focus,
.dark .link-container.selected > a {
    border-bottom: 1px solid #ffffff;
}

.link.blue,
.link-container.blue > a {
    color: #3668c6;
}

.link.blue:hover,
.link.blue:focus,
.link.blue.selected,
.link-container.blue > a:hover,
.link-container.blue > a:focus,
.link-container.blue.selected > a:hover {
    border-bottom: 1px solid #3668c6;
}

.link.green,
.link-container.green > a {
    color: #32951b;
}

.link.green:hover,
.link.green:focus,
.link.green.selected,
.link-container.green > a:hover,
.link-container.green > a:focus,
.link-container.green.selected > a {
    border-bottom: 1px solid #32951b;
}

.link.red,
.link-container.red > a {
    color: #c31f30;
}

.link.red:hover,
.link.red:focus,
.link.red.selected,
.link-container.red > a:hover,
.link-container.red > a:focus,
.link-container.red.selected > a {
    border-bottom: 1px solid #c31f30;
}

.link.grey,
.link-container.grey > a {
    color: #888888;
}

.link.grey:hover,
.link.grey:focus,
.link.grey.selected,
.link-container.grey > a:hover,
.link-container.grey > a:focus,
.link-container.grey.selected > a {
    border-bottom: 1px solid #888888;
}

.link.grey-light,
.link-container.grey-light > a {
    color: #4d4d4d;
}

.link.grey-light:hover,
.link.grey-light:focus,
.link.grey-light.selected,
.link-container.grey-light > a:hover,
.link-container.grey-light > a:focus,
.link-container.grey-light.selected > a {
    border-bottom: 1px solid #4d4d4d;
}

.link.grey-dark,
.link-container.grey-dark > a {
    color: #2d2d2d;
}

.link.grey-dark:hover,
.link.grey-dark:focus,
.link.grey-dark.selected,
.link-container.grey-dark > a:hover,
.link-container.grey-dark > a:focus,
.link-container.grey-dark.selected > a {
    border-bottom: 1px solid #2d2d2d;
}

.link.grey-darker,
.link-container.grey-darker > a {
    color: #000000;
}

.link.grey-darker:hover,
.link.grey-darker:focus,
.link.grey-darker.selected,
.link-container.grey-darker > a:hover,
.link-container.grey-darker > a:focus,
.link-container.grey-darker.selected > a {
    border-bottom: 1px solid #000000;
}

.link.strong,
.link-container.strong > a {
    font-weight: 500;
}

.link.block,
.link-container.block > a {
    display: inline-block;
    width: 100%;
}

.link.no-style,
.link-container.no-style > a {
    border: none;
}

.link.no-select,
.link-container.no-select > a {
    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}
